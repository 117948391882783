import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AbsenceRestrictionModel } from './absence-restriction.model';

export interface AbsenceRestrictionState extends EntityState<AbsenceRestrictionModel> {
  isLoading?: boolean;
  error?: any;
}

export const absenceRestrictionModelEntityAdapter: EntityAdapter<AbsenceRestrictionModel> =
  createEntityAdapter<AbsenceRestrictionModel>({});

export const initialState: AbsenceRestrictionState = absenceRestrictionModelEntityAdapter.getInitialState({
  isLoading: false,
  error: null,
});
